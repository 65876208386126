var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth"},[_c('h1',[_vm._v("Пожалуйста, авторизуйтесь")]),_c('form',{staticClass:"auth__form",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.email),expression:"email",modifiers:{"trim":true}}],class:{
        invalid:
          (_vm.$v.email.$dirty && !_vm.$v.email.required) ||
          (_vm.$v.email.$dirty && !_vm.$v.email.email),
      },attrs:{"autofocus":"","type":"email","name":"email","id":"email","placeholder":"example@artis21.ru"},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.email=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}}),(!_vm.$v.email.$dirty && !_vm.$v.email.required)?_c('label',{attrs:{"for":"email"}},[_vm._v("Введите email")]):(_vm.$v.email.$dirty && !_vm.$v.email.email)?_c('label',{attrs:{"for":"email"}},[_vm._v("Введите корректный email")]):_c('label',{attrs:{"for":"email"}},[_vm._v("Введите email")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.password),expression:"password",modifiers:{"trim":true}}],class:{
        invalid:
          (_vm.$v.password.$dirty && !_vm.$v.password.required) ||
          (_vm.$v.password.$dirty && !_vm.$v.password.minLength),
      },attrs:{"type":"password","name":"password","id":"password","placeholder":"******"},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.password=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}}),(
        (_vm.$v.password.$dirty && !_vm.$v.password.required) ||
        (!_vm.$v.password.$dirty && !_vm.$v.password.required)
      )?_c('label',{attrs:{"for":"password"}},[_vm._v("Введите пароль")]):(_vm.$v.password.$dirty && !_vm.$v.password.minLength)?_c('label',{attrs:{"for":"password"}},[_vm._v("Введите 6-значный пароль")]):_c('label',{attrs:{"for":"password"}},[_vm._v("Введите пароль")]),_c('button',{attrs:{"type":"submit"}},[_vm._v("Войти")])])])}
var staticRenderFns = []

export { render, staticRenderFns }