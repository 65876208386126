export default {
  state: {
    materials: [
      "ВИП",
      "пакет картонный",
      "кружка",
      "упаковка для кружки",
      "шампанское",
      "шоколадный набор",
      "развертка",
      "шоколад 5г",
      "леденец",
      "листовка",
      "шарик зеленый",
      "шарик серый",
      "палочка для шарика",
      "зажим для шарика",
    ],
  },
  mutations: {},
  actions: {},
  getters: {
    getMaterials: (state) => {
      return state.materials;
    },
  },
};
